import React, { memo, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { format, isBefore, subYears} from 'date-fns';

// scss
import "./FamiliesRow.scss";

// components
import Checkbox from "components/Checkbox/Checkbox";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import InfoModal from "components/InfoModal/InfoModal";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

// hooks and utils
import { capitalize } from "utils/utils";
import { fetchRequest } from "utils/fetch";
import { useStateValue } from "state";

// actions
import { ACCOUNT_DELETED } from "actions/accounts";
import { FAMILY_DELETED, FAMILY_INVITE_SENT } from "actions/families";
import { PROFILES_DELETED } from "actions/profiles";

const FamiliesRow = ({
  status,
  id,
  primaryRole,
  defaultRoom,
  inviteSent,
  onboarded,
  createdAt,
  checked,
  email,
  onCheckboxUpdate,
  familyName,
  incomingBabyRooms
}) => {
  const [{accounts, user}, dispatch] = useStateValue();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteMessage, setInviteMessage] = useState("");

  let babyRooms = [];
  const date = new Date(createdAt);
  const today = new Date();
  const twoYearsAgo = subYears(today, 2);
  const isOlderThanTwoYears = isBefore(date, twoYearsAgo);
  const cellStyle = isOlderThanTwoYears ? {
    border: '1px solid red',
    borderRadius: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginLeft: '-10px'
} : {};

  // check and remove duplicate baby rooms
  if(incomingBabyRooms) {
    babyRooms = incomingBabyRooms.filter((room, index) => incomingBabyRooms.indexOf(room) === index && room !== null && room !== '');
  }

  function deleteFamily(accountId) {
    fetchRequest(user.token, "DELETE", `accounts/${accountId}`, undefined)
      .then(result => {
        dispatch({
          type: ACCOUNT_DELETED,
          accountId
        });

        dispatch({
          type: FAMILY_DELETED,
          accountId
        });

        dispatch({
          type: PROFILES_DELETED,
          accountId
        });
      })
      .catch(error => {
        // TODO app error handling
        console.log(error);
      });
  }

  function sendInvite(id) {
    const inviteBody = {
      email,
      type: "family"
    };

    fetchRequest(user.token, "POST", "invite", inviteBody)
      .then(result => {
        // if there is no message invite was sent successfully
        if (!result.message) {
          setInviteMessage(`An Invite has been sent to ID ${id}.`);
          dispatch({
            type: FAMILY_INVITE_SENT,
            id
          });
        } else {
          setInviteMessage(
            "There was an error sending out the invite, try resending."
          );
        }

        setShowInviteModal(true);
      })
      .catch(error => {
        // TODO app error handling
        console.log(error);
      });
  }

  const renderInviteButton = () => {
    if (onboarded) {
      return (
        <Button
          disabled
          variant="secondary"
          className="iwk-families-row__cell-invite"
        >
          Onboarded
        </Button>
      );
    } else if (inviteSent && !onboarded) {
      return (
        <Button
          variant="secondary"
          className="iwk-families-row__cell-invite"
          onClick={() => sendInvite(id)}
        >
          Resend Invite
        </Button>
      );
    } else {
      return (
        <Button
          variant="primary"
          className="iwk-families-row__cell-invite"
          onClick={() => sendInvite(id)}
        >
          Send Invite
        </Button>
      );
    }
  };

  return (
    <div className="iwk-families-row">
      <div className="iwk-families-row__cell -small">
        <Checkbox
          checked={checked}
          onCheckboxCheckedCallback={checked => onCheckboxUpdate(id, checked)}
        />
      </div>
      <div className="iwk-families-row__cell -extend">
        <span>{capitalize(status)}</span>
      </div>

      {primaryRole && (
        <div className="iwk-families-row__cell">
          <span>{capitalize(primaryRole.name)}</span>
        </div>
      )}
      
      <div className="iwk-families-row__cell">
        {familyName}
      </div>

      <div className="iwk-families-row__cell">
          {babyRooms.length > 0 ? (
            babyRooms.map((room, index) => {
              if(babyRooms.length >= 2 && index !== babyRooms.length - 1 && room !== null && babyRooms[index + 1] !== null) {
                return (
                  <span>{room}, </span>
                )
              } else {
                return (
                  <span>{room}</span>
                )
              }
            })
          ) : (
            defaultRoom && (<span>{defaultRoom}</span>)
          )}
        </div>
        
        
          <div className="iwk-families-row__cell -invite -large">
            {status !== 'discharged' && (renderInviteButton())}
          </div>
      <div className="iwk-families-row__cell">
        <span style={cellStyle}>{format(date, "MM/dd/yyyy")}</span>
      </div>
      <div className="iwk-families-row__cell">
        
          {(accounts.access === "super-admin" || accounts.access === "admin") && (
            <button
              className="iwk-families-row__cell-button"
              onClick={() => setShowDeleteModal(!showDeleteModal)}
            >
              <DeleteIcon className="iwk-families-row__cell-icon" />
            </button>
          )}
        
          <button className="iwk-families-row__cell-button -edit">
            <Link to={{ pathname: `/families/${id}/family` }}>
              <EditIcon className="iwk-families-row__cell-icon" />
            </Link>
          </button>
        
      </div>

      <ConfirmationModal
        message={`Did you want to delete ID ${id}? You will not be able to access their data again.`}
        modalCloseAction={() => setShowDeleteModal(!showDeleteModal)}
        confirmAction={() => deleteFamily(id)}
        confirmActionLabel={"Delete"}
        cancelActionLabel={"Cancel"}
        cancelAction={() => setShowDeleteModal(!showDeleteModal)}
        show={showDeleteModal}
        title={"Are you sure?"}
      />

      <InfoModal
        title="Invite Sent"
        message={inviteMessage}
        show={showInviteModal}
        modalCloseAction={() => setShowInviteModal(!showInviteModal)}
      />
    </div>
  );
};

export default memo(FamiliesRow);
